import "./VenueEdit.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from 'usesFeatures'

import Form from "Form"
import Input from "Input"
import Select from "Select"
import Header from "Header"
import Checkbox from "Checkbox"
import Switch from "Switch"

class VenueEdit extends React.Component {
  state = {}

  componentDidMount() {
    this.props.fetchData("venue_admin/venues/current", this.props.seedFormData)
  }

  handleSubmit = () => {
    let req = request.patch("venue_admin/venues/current", {venue: this.props.formData})
    req.then(({data}) => {
      if (data.success) this.setState({saved: true})
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.saved) return <Redirect to="/venue/current" />

    const { venue } = this.state
    const { formData, updateField } = this.props

    return(
      <div className="VenueEdit">
        <Header text={this.props.venue.name} backLink="/venue/current" />
        <div className="">

          <Form onSubmit={this.handleSubmit} title="Edit Venue Info">
            <Input label="Venue Name" onChange={updateField.bind(null, "name")} value={formData.name} />

            <Select
              label="Time Zone"
              options={this.props.timeZones}
              onChange={updateField.bind(null, "timeZone")}
              prompt="Select Time Zone"
              value={formData.timeZone}
            />

            <div className="block field is-grouped">
              <p className="control">
                <button onClick={this.handleSubmit} className="button is-primary">Update</button>
              </p>
              <p className="control">
                <Link to="/venue/current" className="button">Cancel</Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}
export default usesFeatures(["data", "form"], VenueEdit)
