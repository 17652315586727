import "./StoreView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from 'usesFeatures'
import { ApplicationContext } from "ApplicationContext"

import Header from 'Header'
import Switch from 'Switch'
import Confirm from "Confirm"
import ImageField from 'ImageField'
import DeleteButton from 'DeleteButton'
import SeatSelector from 'SeatSelector'

import StoreMenu from './Menu'
import AddProduct from './AddProduct'

class StoreView extends React.Component {
  static contextType = ApplicationContext

  constructor(props) {
    super(props)
    this.state = {
      menu: {}
    }
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.storeId
    this.props.fetchData(`venue_admin/stores/${id}`, this.setMenu)
  }

  refreshMenu = () => {
    this.props.fetchData(`venue_admin/stores/${this.props.store.id}`)
  }

  setMenu = (data) => {
    this.setState({ctegories: data.ctegories, products: data.products})
  }

  toggleAttribute = (attribute, value) => {
    const storeId = this.props.store.id
    let req = request.post(`venue_admin/stores/${storeId}/toggle/${attribute}`, { [attribute]: value })
    req.then(({data}) => this.props.updateWith(data))
  }
  toggleStore = (value) => this.toggleAttribute("open", value)
  toggleTerminalOnly = (value) => this.toggleAttribute("terminal_only", value)
  toggleAllInclusive = (value) => this.toggleAttribute("all_inclusive", value)
  toggleDelivery = (value) => this.toggleAttribute("delivery", value)

  updateSections = ({selectedSection}) => {
    const storeId = this.props.store.id
    let req = request.post(`venue_admin/stores/${storeId}/sections`, { sectionId: selectedSection })
    req.then(({data}) => this.props.updateWith(data))
  }

  render() {
    if (!this.props.dataFetched) return false

    const { store, categories, products, sections } = this.props
    const { user } = this.context.CurrentUserContext

    return(
      <div className="StoreView">
        <Header text={store.name} backLink="/stores" actions={true}>
          <a className="button is-ghost" target="_blank" href={store.orderUrl}>View as Customer</a>
          <Link to={`/stores/${store.id}/edit`} className="button is-primary">Edit Store</Link>
          { user.type != "vendor" && <DeleteButton model="Store" id={store.id} endpoint="stores" /> }
        </Header>
        <div className="columns">
          <div className="column is-2 p-4">
            <div className="Image">
              <ImageField imageUrl={store.imageUrl} type="store" id={store.id} />
            </div>
          </div>

          <div className="column is-10">
            <div className="s-card">
              <div className="columns">
                <div className="block column is-6">
                  <Switch
                    value={store.open}
                    onChange={this.toggleStore}
                    label={<span className="is-size-4 has-text-weight-bold mt-1">Store is { store.open ? "Open" : "Closed" }</span>}
                  />

                  <div className="block">
                    <p className="subtitle">
                      Store Authentication Code
                      <span className="ml-2 has-text-weight-bold"> { store.authCode }</span>
                    </p>
                  </div>

                  <div className="block">
                    <p className="is-size-6 has-text-weight-bold">Description</p>
                    <p className="is-bold">{ store.description }</p>
                  </div>

                  <div className="block">
                    <p className="is-size-6 has-text-weight-bold">Closest Section</p>
                    <p className="is-bold">{ store.closestSection }</p>
                  </div>
                </div>
                <div className="block column is-6">
                  <h4 className="is-size-3">Store Configuration</h4>
                  <Switch
                    value={!store.terminalOnly}
                    onChange={this.toggleTerminalOnly}
                    label={
                      <span className="is-size-5 mt-1">
                        Store <strong>{ store.terminalOnly ? "does not" : "does" }</strong> take mobile orders
                      </span>
                    }
                  />
                  <Switch
                    value={store.allInclusive}
                    onChange={this.toggleAllInclusive}
                    label={
                      <span className="is-size-5 mt-1">
                        Store <strong>{ store.allInclusive ? "is All-inclusive" : "charges for items" }</strong>
                      </span>
                    }
                  />
                  <p className="is-size-5 has-text-weight-bold">What's an all-inclusive store?</p>
                  <p>All-inclusive stores are hidden from the venue directory, and all items are free of charge for fans.</p>
                  { sections.length > 0 &&
                    <Switch
                      value={store.delivery}
                      onChange={this.toggleDelivery}
                      label={
                        <span className="is-size-5 mt-1">
                          Store <strong>{ store.delivery ? "has delivery" : "does not deliver" }</strong>
                        </span>
                      }
                    />
                  }
                  { store.delivery && sections.length > 0 &&
                    <div className="mt-2">
                      <h3 className="subtitle">Delivering to Sections</h3>
                      <div className="field is-grouped is-grouped-multiline">
                        { store.sections.map((s) => {
                          return(
                            <div className="control" key={s.id}>
                              <div className="tags has-addons">
                                <span className="tag is-primary has-text-weight-bold">{ s.name }</span>
                                <span
                                  onClick={this.updateSections.bind(null, {selectedSection: s.id})}
                                  className="tag is-delete is-danger is-outlined"
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>

                      <p className="mt-2">Add Delivery Section</p>
                      <div className="AddSection flex">
                        <SeatSelector
                          filter={store.sections}
                          sections={sections}
                          depth="section"
                          className="is-flex is-align-items-flex-end"
                          action="Add Section"
                          onSubmit={this.updateSections}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="s-card">
          <h3 className="title">Store Menu</h3>
          <AddProduct
            storeId={store.id}
            refreshMenu={this.refreshMenu}
          />

          <StoreMenu
            categories={categories}
            products={products}
            storeId={store.id}
            refreshMenu={this.refreshMenu}
          />
        </div>
      </div>
    )
  }
}
export default usesFeatures(['data'], StoreView)
