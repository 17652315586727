import React from "react";

import Icon from "Icon"

export default class DashboardView extends React.PureComponent {
  render() {
    return(
      <div className="dashboard-tile">
        <div className="tile-head">
          <h3>{ this.props.title }</h3>
          <div className={`h-icon is-${this.props.color} is-rounded`}>
            <Icon icon={this.props.icon} />
          </div>
        </div>
        <div className="dashboard-tile-inner">
          { this.props.children }
        </div>
      </div>
    )
  }
}
