import "./DisplayField.scss";

import React from "react";
import { Link } from "react-router-dom"
import usesFeatures from "usesFeatures"

export default class DisplayField extends React.PureComponent {
  render() {
    return(
      <div className="DisplayField block">
          <p className="is-size-6 has-text-weight-bold">{ this.props.label } </p>
          { !this.props.link ? (
            <p className="is-bold">{ this.props.value }</p>
          ) : (
            <Link to={this.props.link} className="is-bold">{ this.props.value }</Link>
          )}
      </div>
    )
  }
}
