import "./ProductView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Switch from "Switch"
import DeleteButton from "DeleteButton"
import ImageField from "ImageField"
import DisplayField from "DisplayField"

class ProductView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.productId
    this.props.fetchData(`venue_admin/products/${id}`)
  }

  toggleProduct = (productId) => {
    let req = request.post(`venue_admin/products/${productId}/toggle`)
    req.then(() => {
      this.props.fetchData(`venue_admin/products/${this.props.product.id}`)
    })
  }

  handleQuickToggle = (storeId) => {
    const productId = this.props.product.id
    let req = request.post("venue_admin/products/quick_toggle", { storeId, productId })
    req.then(({data}) => {
      this.props.fetchData(`venue_admin/products/${this.props.product.id}`)
    })
  }

  renderModifier = (modifier, key) => {
    return(
      <tr key={key}>
        <td key={key + "n"}>{modifier.name}</td>
        <td key={key + "p"}>${modifier.price}</td>
      </tr>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { product, stores } = this.props
    return(
      <div className="ProductView">
        <Header
          text="Products"
          backLink="/products"
          actions={true}
        >
          <Link to={`/products/${product.id}/edit`} className="button is-primary">Edit Product</Link>
          <DeleteButton model="Product" id={product.id} endpoint="products" />
        </Header>
        <div className="s-card mb-4">
          <div className="columns">
            <div className="column is-2">
              <Switch onChange={this.toggleProduct.bind(null, product.id)} value={product.active} label="Product Status"/>
              <ImageField imageUrl={product.imageUrl} type="product" id={product.id} />
            </div>
            <div className="column is-10">
              <h3 className="title">{ product.name }</h3>

              <DisplayField label="Description" value={ product.description } />
              <DisplayField label="External Product ID" value={ product.externalId } />
              <DisplayField label="Category" value={
                product.category ? (
                  <Link to={`/categories/${product.category.id}`}>{ product.category.name }</Link>
                ) : (
                  <em>No category</em>
                )
              } />
              <DisplayField label="Price" value={`$${product.price}`} />
              { console.log(product) }
              { product.taxEnabled &&
                <DisplayField label="Tax Rate" value={typeof(product.taxRate) == "number" ? `${product.taxRate}%` : "None Set - Will use venue rate"} />
              }
              <div className="block">
                <p className="is-size-6 has-text-weight-bold">Modifiers</p>
                { product.modifiers.length == 0 ? (
                    <p><em>No Modifiers</em></p>
                  ) : (
                    <table className="table is-fullwidth">
                      <tbody>
                        { product.modifierGroups.length > 0 ? (
                            product.modifierGroups.map((g, i) => {
                              return(
                                <React.Fragment key={i}>
                                  { g.name &&
                                    <tr key={i} className="is-selected">
                                      <td colSpan="2" className="has-text-weight-bold">{ g.name }</td>
                                    </tr>
                                  }
                                  { g.modifiers.map((m, im) => this.renderModifier(m, im)) }
                                </React.Fragment>
                              )
                            })
                          ) : (
                            product.modifiers.map((m, im) => this.renderModifier(m, im))
                          )
                        }
                      </tbody>
                    </table>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="s-card mb-4">
          <h3 className="title">Stores</h3>
          { stores.map((store) => {
            return(
              <div key={store.id} className="columns">
                <div className="column">{store.name}</div>
                <div className="column is-narrow">
                  { product.stores.includes(store.id) ? (
                    <span onClick={this.handleQuickToggle.bind(null, store.id)} className="button is-danger">Remove Product</span>
                  ) : (
                    <span onClick={this.handleQuickToggle.bind(null, store.id)} className="button is-success">Add Product</span>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], ProductView)
