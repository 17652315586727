import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash"
import request from "request"

import { formatDateTime } from "date_helpers"

import Flash from "flash"
import Table from "Table"
import Header from "Header"
import Input from "Input"
import Paginate from "Paginate"
import AsyncContainer from "AsyncContainer"

const CustomerList = () => {
  const [ customers, setCustomers ] = useState([])
  const [ pagy, setPagy ] = useState({})
  const [ currentPage, setCurrentPage ] = useState(1)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isNavigating, setIsNavigating ] = useState(false)
  const [ searchValue, setSearchValue ] = useState("")

  const getCustomers = () => {
    setIsNavigating(true)
    const req = request.get(`customers`, { params: {
      page: searchValue ? "1" : currentPage,
      search: searchValue,

    }})
    req.then(({data}) => {
      setIsLoaded(true)
      setIsNavigating(false)
      setCustomers(data.pageData)
      setPagy(data.pagy)
    })
  }

  useEffect(getCustomers, [currentPage])

  const resetSearch = () => {
    setSearchValue("")
    setCurrentPage(1)
  }

  const debouncedSearch = debounce(() => {
    getCustomers()
  }, 250)

  useEffect(debouncedSearch, [ searchValue ])

  return(
    <div>
      <Header
        text="Customers"
      />
      <AsyncContainer isLoaded={isLoaded}>
        <div className="columns">
          <div className="column ml-auto is-one-fifth is-flex">
            { searchValue &&
              <span className="button ml-auto is-link is-outlined" onClick={resetSearch}>Clear Search</span>
            }
          </div>
          <div className="column is-one-quarter">
            <Input
              placeholder="Search by name or email"
              className="one-quarter"
              value={searchValue}
              onChange={setSearchValue}
            />
          </div>
        </div>
        <Table
          isLoading={isNavigating}
          headers={{
            name: "Name",
            lastOrderedAt: "Last Ordered On",
            orderCount: "Lifetime Orders",
            fancyPhone: "Phone",
            email: "Email",
          }}
          formatters={{
            name: (c) => <Link to={`customers/${c.id}`}>{c.name}</Link>,
            lastOrderedAt: (c) => formatDateTime(c.lastOrderedAt),
          }}
          rows={customers}
        />
        <Paginate
          changePage={setCurrentPage}
          {...pagy}
        />
      </AsyncContainer>

    </div>
  )
}

export default CustomerList
