import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { debounce, startCase } from "lodash"
import request from "request"
import dateHelpers from "date_helpers"
import formatCurrency from "utils/formatCurrency"

import Icon from "Icon"
import Flash from "flash"
import Table from "Table"
import Card from "Card"
import Header from "Header"
import Input from "Input"
import Paginate from "Paginate"
import Gravatar from "Gravatar"
import DisplayField from "DisplayField"

import OrderDetails from "./OrderDetails"

const CustomerView = ({computedMatch}) => {
  const [ customer, setCustomer ] = useState({})
  const [ orders, setOrders ] = useState([])
  const [ selectedOrder, setSelectedOrder ] = useState(null)
  const [ isLoaded, setIsLoaded ] = useState(false)

  const customerId = computedMatch.params.customerId
  const getCustomer = () => {
    const req = request.get(`customers/${customerId}`)
    req.then(({data}) => {
      setIsLoaded(true)
      setCustomer(data.customer)
    })
  }

  const getOrders = () => {
    const req = request.get("orders", { params: { customerId: customerId }})
    req.then(({data}) => {
      setOrders(data.orders)
    })
  }

  useEffect(getCustomer, [])
  useEffect(getOrders, [])

  if (!isLoaded) return null
  return(
    <div>
      <div>
        <Link className="button is-ghost mt-4" to="/customers">
          <Icon icon="chevron-left" />&nbsp;
          Back to Customers
        </Link>
      </div>
      <div className="columns">
        <div className="column is-one-quarter pt-4">
          <Card className="is-fullwidth mt-4">
            <div className="mb-4">
              <Gravatar className="image is-fullwidth" email={customer.email} />
            </div>
            <DisplayField label="Name" value={customer.name} />
            <DisplayField label="Phone" value={customer.fancyPhone} />
            <DisplayField label="Email" value={customer.email || "None Provided"} />
            <DisplayField label="Lifetime Order Amount" value={formatCurrency(customer.lifetimeValue)} />
            <DisplayField label="Total Cancelled" value={formatCurrency(customer.revenueLost)} />
            <DisplayField label="Average Items per Order" value={customer.itemsPerOrder} />
          </Card>
        </div>
        <div className="column p-4 mt-4">
          <h2 className="title">Orders</h2>
          <Table
            className="is-fullwidth flex-grow-1"
            rows={orders}
            headers={{
              number: "Order Number",
              status: "Status",
              placedAt: "Placed On",
              completedAt: "Completed At",
              total: "Order Total",
              type: "Type",
            }}
            formatters={{
              number: (o) => <span className="button is-ghost" onClick={() => setSelectedOrder(o.id)}>
                { [ o.store.name, `#${o.number}` ].join(" ") }
              </span>,
              status: (o) => <span className={"tag " + (o.status === "complete" ? "is-success" : o.status === "cancelled" ? "is-danger" : "")}>
                { startCase(o.status) }
              </span>,
              view: (o) => <span className="button is-link">View Order</span>,
              placedAt: (o) => dateHelpers.formatDateTime(o.placedAt),
              completedAt: (o) => dateHelpers.formatDateTime(o.completedAt),
              total: (o) => `$${o.total}`,
              type: (o) => o.type === "terminal" ? "Terminal" : o.isDelivery ? "Mobile Delivery" : "Mobile Pickup",
            }}
          />
          { selectedOrder &&
            <OrderDetails
              orderId={selectedOrder}
              onClose={() => setSelectedOrder(null)}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default CustomerView

