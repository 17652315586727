import React from "react";
import Chart from "react-apexcharts";

const GRAPH_COLORS = {
  primary: "#671cc9",
  primaryMedium: "#d4b3ff",
  primaryLight: "#f4edfd",
  secondary: "#ff227d",
  accent: "#797bf2",
  success: "#06d6a0",
  info: "#039BE5",
  warning: "#faae42",
  danger: "#FF7273",
  purple: "#8269B2",
  blue: "#37C3FF",
  green: "#93E088",
  yellow: "#FFD66E",
  orange: "#FFA981",
  lightText: "#a2a5b9",
  fadeGrey: "#ededed",
};

export default class DashboardView extends React.PureComponent {
  getData = () => {
    const data = []
    this.props.axis.map((x) => data.push(this.props.data[x]))
    return data
  }
  render() {
    return(
      <Chart
        type="line"
        options={{
          chart: {
            id: "carts",
            group: "quickstat",
            sparkline: {
              enabled: true
            },
          },
          stroke: {
            width: 3,
            curve: "smooth",
          },
          xaxis: {
            categories: this.props.axis
          },
          colors: [
            GRAPH_COLORS[this.props.color || "primary"],
          ],
        }}
        series={[
          {
            name: this.props.title,
            data: this.getData(),
          }
        ]}
      />
    )
  }
}

