import "./VenueShow.scss";

import React from "react";
import { Link } from "react-router-dom";
import usesFeatures from 'usesFeatures'

class VenueShow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { computedMatch } = this.props

    this.props.fetchData("venue_admin/venues/current")
  }

  render() {
    if (!this.props.dataFetched) return false


    const { venue } = this.props
    return(
      <div className="VenueShow">
        <div className="columns mt-2">
          <div className="s-card column is-one-third is-offset-one-third">
            <h3 className="block is-size-3">Venue Info</h3>

            <div className="block">
              <p className="is-size-6 has-text-weight-bold">Full Name</p>
              <p className="is-bold">{ venue.name }</p>
            </div>

            <div className="block">
              <p className="is-size-6 has-text-weight-bold">Sales Tax</p>
              <p className="is-bold">{ venue.salesTax ? `${venue.taxRate}%` : "No tax" }</p>
            </div>

            <div className="block">
              <p className="is-size-6 has-text-weight-bold">Timezone</p>
              <p className="is-bold">{ venue.timeZone }</p>
            </div>

            <div className="block">
              <Link to="/venue/edit" className="button is-primary">Edit Venue</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(['data'], VenueShow)
