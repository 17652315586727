import "./Select.scss";

import React from "react";
import { Link } from "react-router-dom";

export default class Select extends React.Component {
  handleOnChange = ({target}) => this.props.onChange(target.value)

  renderOption = ([value, label], i) => {
    return(
      <option key={i} value={value}>{label}</option>
    )
  }

  render() {
    const { prompt, label } = this.props

    return(
      <div className={`Select field block ${this.props.className || ""}`}>
        { label && <label className="label block">{ this.props.label }</label>}
        <div className="select">
          <select
            name={this.props.name ? this.props.name : null}
            onChange={this.handleOnChange}
            value={this.props.value || ""}
            disabled={this.props.disabled || false }
          >
            { prompt && ( <option key="prompt" value="" disabled>{prompt}</option> )}
            { Object.entries(this.props.options || {}).map(this.renderOption) }
          </select>
        </div>
      </div>
    )
  }
}
