import "./SeasonReport.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"
import { flattenDeep } from "lodash"

import StoreReport from "../ReportView/components/StoreReport"

import Icon from "Icon"
import Table from "Table"
import Header from "Header"

// export default class SeasonReport extends React.PureComponent {
class SeasonReport extends React.PureComponent {
  state = {}

  componentDidMount() {
    const id = this.props.computedMatch.params.seasonId
    this.props.fetchData(`venue_admin/reports/seasons/${id}`)
  }

  renderCard = (size, title, data) => {
    return(
      <div className={`column ${size}`}>
        <div className="s-card">
          <h3>{ title }</h3>
          <h2>{ data }</h2>
        </div>
      </div>
    )
  }

  renderFooterTableRow = (name, data, nested = false) => {
    return(
      <tr>
        <td className={`${nested ? "pl-6" : ""}`}>{ name }</td>
        <td className="has-text-right">{ data }</td>
      </tr>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { season, categories, stores, data, dataWarning, showTax, downloadUrl } = this.props

    return(
      <div className="SeasonReport">
        <Header
          backLink="/reports"
          text={`Report for ${season.name}`}
          actions={true}
        >
          <a className="button is-primary" href={downloadUrl}>
            Download Sales CSV
          </a>
        </Header>
        { dataWarning &&
          <div className="message is-warning p-2">
            <p>
              <strong className="mr-2">Warning</strong>
              This event is still in progress, and there are still open stores. It's possible
               this data is not completely accurate
            </p>
          </div>
        }
        <div>
          <div className="Stats columns">
            <div className="column accounting is-half">
              <div className="s-card">

                <h3>Total Sales</h3>
                <h2>{data.sales || 0.00}</h2>

                <h3>Total Tips</h3>
                <h2>{data.tips || 0.00}</h2>

                <h3>Partial Refunds</h3>
                <h2>({ data.refunded })</h2>

                { showTax &&
                  <>
                    <h3>Sales Tax Collected</h3>
                    <h2>{data.tax}</h2>

                    <h3>Sales Tax Refunded</h3>
                    <h2>({data.refundedTax})</h2>
                  </>
                }
                <div className="summary">
                  <h3>Grand Total</h3>
                  <h2>{data.total}</h2>
                </div>
              </div>
            </div>
            <div className="column columns is-multiline">
              { this.renderCard("is-half", "Total Orders", data.orderCount)}
              { this.renderCard(
                "is-half",
                "Orders per Customer",
                data.customerCount ? (data.orderCount/data.customerCount).toFixed(2) : "Unknown"
              )}
              { this.renderCard("is-half", "Average Order Spend", data.avgSpend)}
              { this.renderCard("is-half", "Average Order Size", data.avgSize || "Unknown")}
            </div>
          </div>
          <div className="Stats columns">
            { this.renderCard("is-quarter", "Cancelled Orders", data.cancelledCount)}
            { this.renderCard("is-quarter", "Cancelled Total", data.cancelled)}
            { this.renderCard("is-quarter", "Customers Count", data.customerCount)}
            { this.renderCard("is-quarter", "New Customer Count", data.newCustomerCount)}
          </div>
        </div>

        <div className="ReportWrapper">
          <StoreReport
            entityType="season"
            entityId={season.id}
            renderCard={this.renderCard}
            showTax={showTax}
          />
        </div>

        <div className="Payments columns mt-4">
          <div className="column">
            <div className="s-card">
              <h3 className="title">Sales</h3>

              <table className="table is-hoverable is-fullwidth">
                <tbody>
                  { this.renderFooterTableRow("Gross Sales", data.sales) }
                  { this.renderFooterTableRow("Taxes", data.tax) }
                  { this.renderFooterTableRow("Tips", data.tips) }
                  { this.renderFooterTableRow("Refunded (partial refunds)", `(${data.refunded})`) }
                  { this.renderFooterTableRow("Taxes Refunded", `(${data.refundedTax})`) }
                  <tr className="is-selected">
                    <th>Total Sales</th>
                    <th className="has-text-right">{ data.total }</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          { data.hasAllInclusive &&
            <div className="column">
              <div className="s-card">
                <h3 className="title">All-Inclusive Sales</h3>
                <table className="table is-hoverable is-fullwidth">
                  <tbody>
                    { this.renderFooterTableRow("Total Sales", data.allInclusive.sales) }
                    { this.renderFooterTableRow("Total refunded (partial refunds)", `(${data.allInclusive.refunded})`) }
                    { this.renderFooterTableRow("Total All Inclusive Sales", data.allInclusive.total) }
                    { this.renderFooterTableRow("Catch Fees", `(${data.allInclusive.fees})`) }
                  </tbody>
                </table>
              </div>
            </div>
          }
          <div className="column">
            <div className="s-card">
              <h3 className="title">Payments</h3>
              <table className="table is-hoverable is-fullwidth">
                <tbody>
                  <tr>
                    <th>Total Collected</th>
                    <td className="has-text-right">{ data.total }</td>
                  </tr>
                  <tr>
                    <th colSpan="2">Mobile Orders</th>
                  </tr>
                  { this.renderFooterTableRow("Card", data.paymentTypes.mobile.card, true) }
                  <tr>
                    <th colSpan="2">Terminal Orders</th>
                  </tr>
                  { this.renderFooterTableRow("Card", data.paymentTypes.terminal.card, true) }
                  { this.renderFooterTableRow("Cash", data.paymentTypes.terminal.cash, true) }
                  { this.renderFooterTableRow("Fees", `(${data.fees})`) }
                  <tr className="is-selected">
                    <th>Net Total</th>
                    <th className="has-text-right">{ data.netTotal }</th>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

export default usesFeatures(["data"], SeasonReport)
