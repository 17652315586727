import React from "react";
import { Redirect } from "react-router-dom";
import User from "User";

export default class CrossLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      persisted: false
    }
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    User.setAuthToken(params.get("token"))
    this.setState({persisted: true})
  }

  render() {
    if (this.state.persisted) return <Redirect to="/" />

    return(
      <p>Loading....</p>
    )
  }
}
