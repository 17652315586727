import React from "react";
import "./DashboardView.scss";
import usesFeatures from 'usesFeatures'
import { ApplicationContext } from "ApplicationContext"
import moment from "moment"

import Chart from "react-apexcharts";

import Icon from "Icon"
import Header from "Header"

import Tile from "./Tile"
import SparkLine from "./SparkLine"
import SalesGraph from "SalesGraph"

class DashboardView extends React.PureComponent {
  static contextType = ApplicationContext
  componentDidMount() {
    this.props.fetchData("venue_admin")
  }

  render() {
    if (!this.props.dataFetched) return false

    const { canUse } = this.context.CurrentUserContext

    return(
      <div className="DashboardView ecommerce-dashboard ecommerce-dashboard-v1">
        <Header
          text={`${this.props.venue.name} ${this.props.t("simple.dashboard")}`}
        >
          { canUse("stripe") &&
            <>
              { !this.props.account.active &&
                <a href={`/stripe/${this.props.account.id}/onboard`}>Stripe Onboarding</a>
              }
              { this.props.account.active &&
                <a href={`/stripe/${this.props.account.id}/login`}>Stripe Login</a>
              }
            </>
          }
        </Header>
        <div className="columns is-multiline">
          <div className="column is-12">
            <h3 className="subtitle">Past 7 Events</h3>
          </div>
          <div key="carts" className="column is-3">
            <Tile
              title="Abandoned Carts"
              icon="shopping-cart"
              color="orange"
            >
              <SparkLine
                key="carts"
                title="Abandoned Carts"
                color="orange"
                data={this.props.data.carts}
                axis={this.props.eventDates}
              />
            </Tile>
          </div>
          <div key="sales" className="column is-3">
            <Tile
              title="Sales"
              icon="money-bill-wave"
              color="blue"
            >
              <SparkLine
                key="sales"
                color="blue"
                title="Sales"
                data={this.props.data.sales}
                axis={this.props.eventDates}
              />
            </Tile>
          </div>
          <div key="customers" className="column is-3">
            <Tile
              title="New Customers"
              icon="user"
              color="danger"
            >
              <SparkLine
                key="customer"
                color="danger"
                title="New Customers"
                data={this.props.data.customers}
                axis={this.props.eventDates}
              />
            </Tile>
          </div>
          <div key="orders" className="column is-3">
            <Tile
              title="Average Order Size"
              icon="balance-scale-left"
              color="accent"
            >
              <SparkLine
                key="order"
                color="accent"
                title="Average Order Size"
                data={this.props.data.average}
                axis={this.props.eventDates}
              />
            </Tile>
          </div>
          <div className="column is-12">
            <h3 className="subtitle">Running Stats</h3>
          </div>
          <div className="column is-6">
            <SalesGraph
              title="Sales by Month"
              data={this.props.sales}
            />
          </div>
          <div className="column is-6">
            <div className="stat-widget flex-stat-widget is-straight">
              <div className="chart-media">
                <div className="meta">
                  <h4 className="dark-inverted">Orders with Returns</h4>
                  <p>
                    Running average of how many orders have items refunded in them
                  </p>
                </div>
                <div className="chart-container">
                  <Chart
                    type="radialBar"
                    options={{
                      chart: {
                        height: 130,
                        offsetX: -10,
                        toolbar: {
                          show: false,
                        },
                        labels: ["Average %"]
                      },
                      plotOptions: {
                        radialBar: {
                          hollow: {
                            size: "75%",
                          },
                          dataLabels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "12px",
                              fontWeight: 400,
                              offsetY: 5,
                            },
                            value: {
                              show: true,
                              fontWeight: 600,
                              fontFamily: "Roboto, sans-serif",
                              fontSize: "16px",
                              offsetY: 5,
                            },
                          },
                        },
                      },
                    }}
                    series={ [ (this.props.refunds * 100).toFixed(2) ] }
                  />
                </div>
              </div>
            </div>
            <div className="stat-widget flex-stat-widget is-straight mt-4">
              <h4 className="dark-inverted subtitle">Top Selling Products</h4>
              <div className="columns has-text-weight-bold">
                <div className="column">Product Name</div>
                <div className="column is-3">Total Sales</div>
                <div className="column is-3">Total Revenue</div>
              </div>
              { this.props.topProducts.map((p, i) => {
                return(
                  <div className="columns" key={i}>
                    <div className="column">{ p.name }</div>
                    <div className="column is-3">{ p.sold }</div>
                    <div className="column is-3">${ ((p.sold * p.price)/100).toFixed(2) }</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <p>
          <em>
            Stats are calculated periodically, and are not real time
          </em>
        </p>
      </div>
    )
  }
}
export default usesFeatures(["data", "translation"], DashboardView)
