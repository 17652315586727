import React, { useState, useEffect } from "react"
import request from "lib/request";

const getInitialData = (endpoint, dataLabel) => {
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ data, setData ] = useState(null)

  useEffect(() => {
    let req = request.get(endpoint)
    req.then(({data}) => {
      setData(data[dataLabel])
    })
    req.finally(() => {
      setIsLoaded(true)
    })
  }, [])

  return {
    isLoaded: isLoaded,
    [dataLabel]: data,
  }
}

export default getInitialData
