import "./ImageField.scss";

import React from "react";
import request from "request"

import Modal from "Modal"
import FileField from "FileField"
import Image from 'Image'

export default class ImageField extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      imageUrl: props.imageUrl,
      imageError: false,
    }
  }

  toggleModal = () => this.setState({modal: !this.state.modal})

  updateImage = (data) => {
    this.setState({image: data})
  }

  handleUploadImage = () => {
    if(!this.state.image) return false
    this.setState({imageError: false})
    let formData = new FormData()
    formData.append("image", this.state.image)
    formData.append("ownerId", this.props.id)
    formData.append("ownerType", this.props.type)

    let req = request.post("venue_admin/image", formData )
    req.then(({data}) => {
      this.setState({imageId: data.imageId, imageUrl: "", modal: false, image: null})
      this.fetchImage(data.imageId)
    })
    req.catch(({response}) => {
      this.setState({imageError: true})
    })
  }

  fetchImage = (imageId) => {
    let req = request.get(`images/${imageId}`)
    req.then(({data}) => {
      if (data.url) {
        this.setState({imageUrl: data.url})
      } else {
        setTimeout(this.fetchImage.bind(null, imageId), 1000)
      }
    })
  }

  render() {
    return(
      <div className="ImageField">
        <div className="has-text-right">
          <Image loading={this.state.imageId && !this.state.imageUrl} src={this.state.imageUrl} ratio="square" />
          <div>
            <a onClick={this.toggleModal}>Change image</a>
          </div>
        </div>
        <Modal open={this.state.modal} toggleModal={this.toggleModal}>
          <div className="columns">
            <div className="column is-9">
              <FileField
                label={`Select new ${this.props.type} image`}
                onChange={this.updateImage}
                value={this.state.image}
              />
              <div className="has-text-right">
                <span onClick={this.handleUploadImage} className="button is-primary" disabled={!this.state.image}>Save Image</span>
              </div>
            </div>
            { this.state.image && (
              <div className="column is-3">
                <p>Selected Image</p>
                <img src={URL.createObjectURL(this.state.image)} />
              </div>
            )}
          </div>
          { this.state.imageError &&
            <p className="text-danger">
              Invalid image type. Type must be JPG, PNG, or GIF
            </p>
          }
        </Modal>
      </div>
    )
  }
}

