import "./PaymentsReportView.scss";

import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"

import Header from "Header"

class PaymentsReportView extends React.PureComponent {
  state = {}

  componentDidMount() {
    const { computedMatch, match } = this.props
    const id = (computedMatch || match).params.eventId
    this.props.fetchData(`venue_admin/reports/${id}/payments`)
  }

  renderCard = (size, title, data) => {
    return(
      <div className={`column ${size}`}>
        <div className="s-card">
          <h3>{ title }</h3>
          <h2>{ data }</h2>
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { event, mobileData, terminalData, dataWarning } = this.props

    return(
      <div className="PaymentsReportView">
        <Header
          backLink="/reports"
          text={`Payments Report for ${event.name} - ${moment(event.date).format("MMM Do YYYY")}`}
          actions={true}
        >
        </Header>
        { dataWarning &&
          <div className="message is-warning p-2">
            <p>
              <strong className="mr-2">Warning</strong>
              This event is still in progress, and there are still open stores. It's possible
               this data is not completely accurate
            </p>
          </div>
        }
        <h2 className="subtitle has-text-weight-bold is-size-3">Mobile Sales</h2>
        <div className="Stats columns accounting">
          { this.renderCard("is-half", "Mobile Sales", mobileData.total) }
          { this.renderCard("is-half", "Mobile Tips", mobileData.tips) }
        </div>

        <h2 className="subtitle has-text-weight-bold is-size-3">Terminal Sales</h2>
        { terminalData.length == 0 &&
          <p className="has-text-centered is-size-5">
            <em>No Terminals at this venue. Contact us to learn more about Catch Terminal</em>
          </p>
        }
        { terminalData.map((terminalRow, i) => {
          return(
            <div key={i} className="mb-4">
              <h3 className="subtitle is-size-3">{ terminalRow.terminalName }</h3>
              <div className="Stats columns accounting">
                { this.renderCard("is-quarter", "Total Sales", terminalRow.total) }
                { this.renderCard("is-quarter", "Tips", terminalRow.tips) }
                { this.renderCard("is-quarter", "Card Sales + Tax", terminalRow.cardTotal) }
                { this.renderCard("is-quarter", "Cash Total", terminalRow.cashTotal) }
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default usesFeatures(["data"], PaymentsReportView)
