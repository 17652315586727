import React, { useState, useEffect } from "react";

import Loader from "Loader"

const AsyncContainer = ({isLoaded, children}) => {
  if (!isLoaded) return <Loader />

  return children
}

export default AsyncContainer
