import "./ReportList.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import usesFeatures from "usesFeatures"
import request from "request"

import moment from "moment"

import Table from "Table"
import Card from "Card"
import Input from "Input"
import Button from "Button"
import DatePicker from "DatePicker"
import Header from "Header"

class ReportList extends React.PureComponent {
  state = {
    scope: "recent",
    isSubmittingReport: false,
  }

  componentDidMount() {
    this.props.fetchData(`/venue_admin/reports?scope=${this.state.scope}`)
  }

  changeScope = (scope) => {
    this.setState({scope: scope})
    this.props.fetchData(`/venue_admin/reports?scope=${scope}`)
  }

  renderScopeButton = (scope, label) => {
    return(
      <p className="control">
        <span
          className={`button is-primary ${ this.state.scope == scope ? "" : "is-outlined" }`}
          onClick={this.changeScope.bind(null, scope)}
        >
          { label || scope }
        </span>
      </p>
    )
  }

  createReport = () => {
    this.setState({isSubmittingReport: true})

    const { startDate, endDate } = this.state

    const req = request.post("reports", {
      report: {
        startDate, endDate
      }
    })

    req.then(({data}) => {
      if(data.report) {
        this.setState({newReportId: data.report.id})
      }
    })
    req.finally(() => {
      this.setState({isSubmittingReport: false})
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { scope } = this.state

    if(this.state.newReportId) return <Redirect to={`/reports/custom/${this.state.newReportId}`} />

    return(
      <div className="ReportList">
        <Header
          text="Reports"
          actions
        >
          <div className="Dates field has-addons button-group ml-auto">
            { this.renderScopeButton("recent", "Recent Events") }
            { this.renderScopeButton("past", "Past Events") }
            { this.renderScopeButton("reports", "Custom Reports") }
          </div>
        </Header>
        <Table
          showHeader={true}
          headers={scope === "reports" ? REPORT_HEADERS : EVENT_HEADERS}
          formatters={FORMATTERS}
          rows={this.props.events}
          empty={scope === "reports" ? "No Reports Found" : "No events found"}
        />
        { scope === "reports" &&
          <Card
            title="New Report"
          >
            <div className="is-flex is-align-items-end is-flex-direction-col">
              <div className="is-flex-grow-1 mr-4">
                <DatePicker
                  label="Start Date"
                  value={this.state.startDate}
                  onChange={(startDate) => this.setState({startDate})}
                />
              </div>
              <div className="is-flex-grow-1 mr-4">
                <DatePicker
                  label="End Date"
                  value={this.state.endDate}
                  onChange={(endDate) => this.setState({endDate})}
                />
              </div>
              <div>
                <Button
                  text="Create Report"
                  disabled={!(this.state.startDate && this.state.endDate && moment(this.state.startDate).isBefore(this.state.endDate))}
                  loading={this.state.isSubmittingReport}
                  className="mb-1"
                  onClick={this.createReport}
                />
              </div>
            </div>
            {(this.state.startDate && this.state.endDate) && moment(this.state.startDate).isAfter(this.state.endDate) &&
              <div className="message is-danger p-2 mt-4">
                Invalid Date Selection. <strong>End Date</strong> must be after the <strong>Start Date</strong>
              </div>
            }
          </Card>
        }
      </div>
    )
  }
}

const EVENT_HEADERS = {
  name: "Event Name",
  visitor: "Visitor",
  date: "Date",
  startTime: "Start Time",
  view: "",
  paymentsReport: "",
}

const FORMATTERS = {
  date: (e) => moment(e.date).format("MMM Do YYYY"),
  startTime: (e) => e.startTime ? moment(e.startTime).format("hh:mma") : null,
  view:(e) => <Link to={`/reports/${e.id}`}>Sales Report</Link>,
  paymentsReport:(e) => <Link to={`/reports/payments/${e.id}`}>Payments Report</Link>,
  createdAt: (r) => moment(r.createdAt).format("MMM Do YYYY hh:mma"),
  viewReport:(r) => <Link to={`/reports/custom/${r.id}`}>View Report</Link>,
}

const REPORT_HEADERS = {
  name: "Report Name",
  startDate: "Start Date",
  endDate: "End Date",
  createdByName: "Generated by",
  createdAt: "Created",
  viewReport: "",
}

export default usesFeatures(["data"], ReportList)
