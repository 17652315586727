import "./StoreList.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from 'usesFeatures'
import { ApplicationContext } from "ApplicationContext"

import Table from 'Table'
import Header from 'Header'

class StoreList extends React.Component {
  static contextType = ApplicationContext

  componentDidMount() {
    this.props.fetchData("venue_admin/stores")
  }

  emptyTable = () => {
    return(
      <h3 className="is-size-6 is-italic">
        No Store Found. Time to <Link to="/stores/new">create one</Link>?
      </h3>
    )
  }

  storeActions = () => {
    return(
      <>
        <span className="button h-button is-success" onClick={this.toggleAllStores.bind(null, true)}>Open all</span>
        <span className="button h-button is-danger ml-4" onClick={this.toggleAllStores.bind(null, false)}>Close all</span>
      </>
    )
  }

  toggleAllStores = (open) => {
    let req = request.post("venue_admin/stores/toggle", { open })
    req.then(() => this.props.fetchData("venue_admin/stores"))
  }

  render() {
    if (!this.props.dataFetched) return false

    const { stores } = this.props
    const { user } = this.context.CurrentUserContext
    return(
      <div className="StoreList">
        <Header text={`Stores at ${ this.props.venue.name }`} />
        <div className="block">
          <Table
            newRecordPath="/stores/new"
            newRecordText="New Store"
            hideNewRecord={user.type == "vendor"}
            empty={this.emptyTable()}
            showHeader={true}
            headers={STORE_HEADERS}
            formatters={STORE_FORMATTERS}
            tdClasses={STORE_CLASSES}
            rows={stores}
            actions={this.storeActions()}
          />
        </div>
      </div>
    )
  }
}

const STORE_FORMATTERS = {
  name: (s) => <Link to={`/stores/${s.id}`}>{s.name}</Link>,
  open: (s) => <span className={`storestatus ${s.open ? "open" : "closed"}`}>{ s.open ? "Open" : "Closed" }</span>,
  allInclusive: (s) => s.allInclusive ? "Yes" : "No",
  edit: (s) => <Link to={`/stores/${s.id}/edit`}>Edit</Link>,
}

const STORE_HEADERS = {
  name: "Store Name",
  open: "Open?",
  allInclusive: "All-inclusive?",
  edit: "",
}

const STORE_CLASSES = {
  open: "is-narrow",
  edit: "is-narrow",
}

export default usesFeatures(['data'], StoreList)
