import React from "react";

import request from "request"
import { debounce } from "lodash"

import Form from "Form";
import Input from "Input";
import Select from "Select";
import CurrencyInput from "CurrencyInput";

export default class AddProduct extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      searchTerm: "",
      searchScope: "products",
      products: [],
      categories: [],
    }
  }

  updateSearchTerm = (searchTerm) => {
    this.setState({searchTerm: searchTerm, searched: false})
    this.executeSearch()
  }

  updateSearchScope = (scope) => {
    this.setState({searchScope: scope, searched: false})
  }

  executeSearch = debounce(() => {
    const { searchScope, searchTerm } = this.state
    if (searchTerm == "") {
      this.setState({products: []})
      return false
    } else {
      this.setState({searching: true, products: []})
      const url = [ "venue_admin", "search", searchScope ].join("/")
      let req = request.get(`${url}?term=${searchTerm}&store_id=${this.props.storeId}`)
      req.then(({data}) => {
        this.setState({products: data.products, categories: data.categories, searching: false, searched: true})
      })
    }
  }, 500)

  renderProduct = (product) => {
    return(
      <div className="columns" key={product.id}>
        <div className="column is-7">
          <p className="is-size-5">{ product.name }</p>
          <p> { product.description } </p>
        </div>
        <div className="column is-2 has-text-center">
          ${ product.price }
        </div>
        <div className="column is-3 has-text-right">
          <span onClick={this.addProduct.bind(null, product.id)} className="button is-primary">Add Product</span>
        </div>
      </div>
    )
  }

  renderCategory = (category) => {
    return(
      <div className="columns" key={category.id}>
        <div className="column is-7">
          <p className="is-size-5">{ category.name }</p>
          <p> { category.description } </p>
        </div>
        <div className="column is-2 has-text-center">
          { category.productCount } products
        </div>
        <div className="column is-3 has-text-right">
          <span onClick={this.addCategory.bind(null, category.id)} className="button is-primary">Add All Products</span>
        </div>
      </div>
    )
  }

  resetState = () => {
    this.setState({searched: false, searching: false, products: [], categories: [], searchTerm: ""})
  }

  addProduct = (productId) => {
    let req = request.post(`venue_admin/stores/${this.props.storeId}/products`, { productId })
    req.then(({data}) => {
      if (data.success) {
        this.resetState()
        this.props.refreshMenu()
      }
    })
  }

  addCategory = (categoryId) => {
    let req = request.post(`venue_admin/stores/${this.props.storeId}/products`, { categoryId })
    req.then(({data}) => {
      if (data.success) {
        this.resetState()
        this.props.refreshMenu()
      }
    })
  }

  render() {
    const { searchScope, searching, searched, categories, products } = this.state
    return(
      <div className="block">
        <h4 className="is-size-4 block">Quick add by</h4>
        <div className="columns">
          <div className="column is-4">
            <Select
              onChange={this.updateSearchScope}
              value={searchScope}
              options={{
                products: "Product Name",
                categories: "Category",
              }}
            />

          </div>
          <div className="column is-8">
            <Input
              placeholder="Search..."
              value={this.state.searchTerm}
              onChange={this.updateSearchTerm}
            />
          </div>
        </div>
        { searching && <p>Searching.... </p> }
        { searched && categories.length == 0 && products.length == 0 && (
          <>
            <p>
              No { this.state.searchScope } matching { this.state.searchTerm }
              <a className="ml-2" onClick={this.resetState}>Clear Search</a>
            </p>
          </>
        )}
        { this.state.products && this.state.products.map(this.renderProduct)}
        { this.state.categories && this.state.categories.map(this.renderCategory)}
      </div>
    )
  }
}

