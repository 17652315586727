import "./ProductForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";

import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"

import Form from "Form"
import Input from "Input"
import Select from "Select"
import Switch from "Switch"
import Textarea from "Textarea"
import CurrencyInput from "CurrencyInput"

class ProductForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      stagedModifier: {},
      modifierGroups: [],
      modifiers: [],
    }
  }

  componentDidMount() {
    const productId = this.props.computedMatch.params.productId
    if (productId) {
      this.props.fetchData(`venue_admin/products/${productId}/edit`, this.seedForm)
    } else {
      this.props.fetchData("venue_admin/products/new", this.seedForm)
    }
  }

  headerText = () => this.props.formData.id ? "Edit Product" : "New Product"

  seedForm = (data) => {
    let params = new URLSearchParams(document.location.search.substring(1));
    if (params.get("categoryId")) {
      data.formData.categoryId = params.get("categoryId")
    }
    this.props.seedFormData(data)
    this.setState({modifierGroups: data.formData.modifierGroups || [{name: "", modifiers: []}]})
  }

  addModifier = (groupIndex) => {
    const { modifierGroups } = this.state
    modifierGroups[groupIndex].modifiers.push({})
    this.setState(modifierGroups)
  }

  addModifierGroup = () => {
    const { modifierGroups } = this.state
    modifierGroups.push({modifiers: []})
    this.setState(modifierGroups)
  }

  removeModifierGroup = (groupIndex) => {
    const { modifierGroups } = this.state
    modifierGroups.splice(groupIndex, 1)
    this.setState(modifierGroups)
  }

  renderModifierGroup = (group, i) => {
    return(
      <div key={i} className="box has-background-link-light is-bordered mb-6">
        <div className="is-flex">
          <h3 className="title">Modifier Group</h3>
          <span onClick={this.removeModifierGroup.bind(null, i)} className="button is-danger ml-auto">
            Remove Group
          </span>
        </div>
        <Input label="Group Name" value={group.name} onChange={this.updateModifierGroup.bind(null, i, "name")} />
        <div className="columns">
          <div className="column is-6 mt-4">
            <Switch
              value={group.required}
              onChange={this.updateModifierGroup.bind(null, i, "required")}
              label={<span className="has-text-weight-bold mt-1">Selection is { group.required ? "Required" : "Optional" }</span>}
            />
          </div>
          <div className="column is-6">
            <Input label="Maximum Allowed" value={group.max} onChange={this.updateModifierGroup.bind(null, i, "max")} />
          </div>
        </div>
        { this.renderModifiers(i, group.modifiers) }
        <span className="button button-link cursor-pointer" onClick={this.addModifier.bind(null, i)}>Add new modifier</span>
      </div>
    )
  }

  renderModifiers = (groupIndex, modifiers) => {
    if (!modifiers || modifiers.length == 0) return <p className="block is-italic">No Modifiers</p>
    return <div className="block">
        <div key="header" className="columns has-text-weight-bold">
          <div className="column is-8">
            Modifier Name
          </div>
          <div className="column is-3">
            Modifier Price
          </div>
          <div className="column is-1">
          </div>
        </div>
      { modifiers.map((m, i) => {
        return(
          <div key={i} className="columns">
            <div className="column is-8">
              <Input value={m.name} onChange={this.updateModifier.bind(null, groupIndex, i, "name")} />
            </div>
            <div className="column is-3">
              <CurrencyInput
                className="input"
                prefix="$"
                value={m.price}
                onChange={this.updateModifier.bind(null, groupIndex, i, "price")}
              />
            </div>
            <div className="column is-1">
              <span className="button is-danger" onClick={this.handleRemoveModifier.bind(null, groupIndex, i)}>-</span>
            </div>
          </div>
        )
      })}
    </div>
  }

  updateStagedModifier = (field, value) => {
    const { stagedModifier } = this.state
    stagedModifier[field] = value
    this.setState(stagedModifier)
  }

  updateModifier = (groupIndex, index, field, value) => {
    const { modifierGroups } = this.state
    modifierGroups[groupIndex].modifiers[index][field] = value
    this.setState({modifierGroups})
  }

  updateModifierGroup = (index, field, value) => {
    let modifierGroups = this.state.modifierGroups
    modifierGroups[index][field] = value
    this.setState({modifierGroups})
  }

  handleRemoveModifier = (groupIndex, index) => {
    const { modifierGroups } = this.state
    modifierGroups[groupIndex].modifiers.splice(index, 1)
    this.setState({modifierGroups})
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let product = this.props.formData
    let modifierGroups = this.state.modifierGroups

    const productId = this.props.formData.id
    const method = productId ? "patch" : "post"
    const url = productId ? `products/${productId}` : "products"

    let req = request[method](`venue_admin/${url}`, { product, modifierGroups })
    req.then(({data}) => {
      if (data.success) {
        this.setState({saved: true, id: data.product.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.saved && this.state.id) return <Redirect to={`/products/${this.state.id}`} />

    const { updateField, formData } = this.props
    const { stagedModifier } = this.state

    return(
      <div className="ProductForm">
        <Header
          text="Products"
          backLink={`/products${formData.id ? ("/" + formData.id) : ""}` }
        />
        <Form
          onSubmit={this.handleSubmit}
          title={this.headerText()}
        >
          <Input label="Product Name" onChange={updateField.bind(null, "name")} value={formData.name} />
          <Textarea label="Product Description" onChange={updateField.bind(null, "description")} value={formData.description} />
          <Input
            label="External Id"
            onChange={updateField.bind(null, "externalId")}
            value={formData.externalId}
            hint="External ID, used for cross referencing reports"
          />
          <CurrencyInput
            label="Price"
            onChange={updateField.bind(null, "price")}
            value={formData.price}
            placeholder="$5.50"
            allowNegativeValue={false}
          />

          { initialState.venue.salesTax &&
            <Input
              label="Tax Rate"
              onChange={updateField.bind(null, "taxRate")}
              value={`${typeof(formData.taxRate) != "object" ? formData.taxRate : ""}`}
              append="%"
              hint="Tax rate for this product. Will use the venue rate if blank, if not taxed set to 0"
            />
          }

          <Select
            label="Product Category"
            options={this.props.categories}
            onChange={updateField.bind(null, "categoryId")}
            value={formData.categoryId}
            disabled={Object.keys(this.props.categories).length == 0}
            prompt={Object.keys(this.props.categories).length > 0 ? "Select a Category" : "No Categories Found "}
          />

          <h3 className="block mt-5 is-size-4 has-text-weight-bold">Product Modifiers</h3>

          { this.state.modifierGroups.map((mg, i) => {
            return this.renderModifierGroup(mg, i)
          })}
          <span className="button button-primary cursor-pointer" onClick={this.addModifierGroup}>Add new Modifier Group</span>

          <div className="block field is-grouped mt-4">
            <p className="control">
              <button onClick={this.handleSubmit} className="button is-primary">{ formData.id ? "Update" : "Create"} Product</button>
            </p>
            <p className="control">
              <Link to={ formData.id ? `/products/${formData.id}` : "/products"} className="button">Cancel</Link>
            </p>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(['form', 'data'], ProductForm)
