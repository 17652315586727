import "./Card.scss";
import React from "react";

export default class Card extends React.PureComponent {
  render() {
    return(
      <div className={`Card s-card mb-4${ this.props.className ? ` ${this.props.className}`: ""}`}>
        { this.props.image &&
          <div className="card-image">
            <figure className="image is-4by2">
              <img src={this.props.image} />
            </figure>
          </div>
        }
        { this.props.title &&
          <div className="card-head mb-4">
            <div className="left">
              <h3 className="title is-5">{ this.props.title }</h3>
            </div>
            <div className="right">
              { this.props.actions }
            </div>
          </div>
        }
        { this.props.children }
      </div>
    )
  }
}
