import "./ForgotPassword.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Form from "Form"
import Input from "Input"

class ForgotPassword extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  canSubmit = () => {
    const { formData } = this.props
    return formData.email && formData.email.includes("@") && formData.email.includes(".")
  }

  handleResetPassword = () => {
    if (this.canSubmit()) {
      let req = request.post("/password/send-reset",  { user: { email: this.props.formData.email }})
      req.then(({data}) => {
        this.setState({resetSent: true})
      })
    }
  }

  render() {
    if (this.state.resetSent) return (
      <div className="ForgotPassword">
        <div className="has-text-centered block">
          <p className="block">Password reset sent to {this.props.formData.email }</p>
          <p className="block">If you don't receive the email in a few minutes, please check your spam </p>
        </div>
      </div>
    )
    return(
      <div className="ForgotPassword">
        <div className="has-text-centered">
          Forgot your password? No worries, we can reset it for you. Simply put your login
           email below, and we'll send you a password reset link.
        </div>

        <Form
          noWrapper={true}
          onSubmit={this.handleResetPassword}
        >
          <Input
            label="Email"
            value={this.props.formData.email}
            onChange={this.props.updateField.bind(null, "email") }
          />

          <button type="submit" disabled={!this.canSubmit()} className="button is-primary">Reset Password</button>
        </Form>
        <Link to="/login">Back to Log In</Link>
      </div>
    )
  }
}

export default usesFeatures(['form'], ForgotPassword)
