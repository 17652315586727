import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import wrapComponent from "ApplicationWrapper"
import User from "User"

import CrossLogin from "CrossLogin"
import FourOhFour from "FourOhFour"
import ProtectedRoute from 'ProtectedRoute'

import LoginView from "VenueAdmin/LoginView";
import SignUpView from "VenueAdmin/SignUpView";

import ForgotPassword from "VenueAdmin/ForgotPassword"
import ResetPassword from "VenueAdmin/ResetPassword"

import DashboardView from "VenueAdmin/DashboardView"
import Profile from "VenueAdmin/Profile"

import VenueShow from "VenueAdmin/VenueShow"
import VenueEdit from "VenueAdmin/VenueEdit"

import StoreList from "VenueAdmin/StoreList"
import StoreForm from "VenueAdmin/StoreForm"
import StoreView from "VenueAdmin/StoreView"

import ProductList from "VenueAdmin/ProductList"
import ProductForm from "VenueAdmin/ProductForm"
import ProductView from "VenueAdmin/ProductView"

import CategoryList from "VenueAdmin/CategoryList"
import CategoryForm from "VenueAdmin/CategoryForm"
import CategoryView from "VenueAdmin/CategoryView"

import ReportList from "VenueAdmin/ReportList"
import ReportView from "VenueAdmin/ReportView"
import PaymentsReportView from "VenueAdmin/PaymentsReportView"
import SeasonReport from "VenueAdmin/SeasonReport"

import TerminalsList from "VenueAdmin/TerminalsList"

import CustomerList from "VenueAdmin/CustomerList"
import CustomerView from "VenueAdmin/CustomerView"

export default props => (
  <Router>
    <Switch>
      <Route path="/login" exact component={wrapComponent(LoginView, "Auth")} />
      <Route path="/forgot-password" exact component={wrapComponent(ForgotPassword, "Auth")} />
      <Route path="/password-reset" exact component={wrapComponent(ResetPassword, "Auth")} />
      <Route path="/sign-up/:invitationToken" exact component={wrapComponent(SignUpView, "Auth")} />

      <ProtectedRoute path="/" exact component={wrapComponent(DashboardView, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/profile" exact component={wrapComponent(Profile, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/venue/edit" exact component={wrapComponent(VenueEdit, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/venue/current" exact component={wrapComponent(VenueShow, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/stores" exact component={wrapComponent(StoreList, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/stores/new" exact component={wrapComponent(StoreForm, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/stores/:storeId" exact component={wrapComponent(StoreView, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/stores/:storeId/edit" exact component={wrapComponent(StoreForm, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/products" exact component={wrapComponent(ProductList, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/products/new" exact component={wrapComponent(ProductForm, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/products/:productId/edit" exact component={wrapComponent(ProductForm, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/products/:productId" exact component={wrapComponent(ProductView, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/categories" exact component={wrapComponent(CategoryList, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/categories/new" exact component={wrapComponent(CategoryForm, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/categories/:categoryId/edit" exact component={wrapComponent(CategoryForm, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/categories/:categoryId" exact component={wrapComponent(CategoryView, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/terminals" exact component={wrapComponent(TerminalsList, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/reports" exact component={wrapComponent(ReportList, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/reports/payments/:eventId" exact component={wrapComponent(PaymentsReportView, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/reports/:reportId" exact component={wrapComponent(ReportView, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/reports/season/:seasonId" exact component={wrapComponent(SeasonReport, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/reports/:reportType/:reportId" exact component={wrapComponent(ReportView, "VenueAdmin")} auth={User} />

      <ProtectedRoute path="/customers" exact component={wrapComponent(CustomerList, "VenueAdmin")} auth={User} />
      <ProtectedRoute path="/customers/:customerId" exact component={wrapComponent(CustomerView, "VenueAdmin")} auth={User} />

      <Route path="/cross-login" exact component={CrossLogin} />
      <Route path="/*" component={wrapComponent(FourOhFour)} />
    </Switch>
  </Router>
);
