import "./SeatSelector.scss";

import React from "react";
import usesFeatures from "usesFeatures"
import { sortedUniq, zipObject, capitalize } from "lodash"

import Select from "Select"

export default class SeatSelector extends React.PureComponent {
  state = {
    area: "",
    seatType: "",
    selectedArea: "",
    selectedSection: "",
    selectedRow: "",
    selectedSeat: "",
    sectionOnly: false,
  }

  componentDidMount() {
    const selected = {}
    let areas = this.areas()
    if (areas.length == 1) {
      selected.selectedArea = areas[0]
    }
    if (this.props.sections.length == 1) {
      const section = this.props.sections[0]
      selected.seatType = section["type"]
      selected.selectedSection = section.id
    }
    if (Object.keys(selected).length > 0) {
      this.setState({...selected})
    }
  }

  canSubmit = () => {
    const { selectedArea, selectedSection, selectedRow, selectedSeat } = this.state
    if(this.depth() == 3 && selectedSeat) return true
    if(this.depth() == 2 && selectedRow) return true
    if(this.depth() == 1 && selectedSection) return true
    if(this.depth() == 0 && selectedArea) return true
    return false
  }
  depth = () => [ "area", "section", "row", "seat" ].indexOf(this.props.depth)

  areas = () => sortedUniq(this.props.sections.map((s) => s.area).sort())

  updateSelection = (field, value) => {
    let state = Object.assign({}, this.state)

    state[field] = value
    if (field == "selectedArea") {
      state = Object.assign(state, {selectedSection: null, selectedRow: null, selectedSeat: null})
    }
    if (field == "selectedSection") {
      state["seatType"] = this.findSection(value).type
      state["sectionOnly"] = this.skipSeats(value)
      state = Object.assign(state, {selectedRow: null, selectedSeat: null})
    }
    if (field == "selectedRow") {
      state = Object.assign(state, {selectedSeat: null})
    }
    this.setState({...state}, this.change)
  }

  sectionsFor = (section) => {
    const sections = this.props.sections.filter((s) => s.area == this.state.selectedArea)
    const opts = {}
    sections.map((s) => {
      if (!(this.props.filter || []).find((x) => x.id == s.id)) {
        opts[s.id] = s.name
      }
    })
    return opts
  }

  findSection = (sectionId) => this.props.sections.find((x) => x.id === sectionId)

  rowsFor = (section) => {
    const rows = this.findSection(section).rows
    return zipObject(rows, rows)
  }

  seatsFor = (section) => {
    const seats = this.findSection(section).seats
    return zipObject(seats, seats)
  }

  skipRows = (sectionId) => {
    if (!sectionId) return false
    const section = this.findSection(sectionId)
    return !section.firstRow && !section.lastRow
  }

  skipSeats = (sectionId) => {
    if (!sectionId) return false
    const section = this.findSection(sectionId)
    return !section.firstSeat && !section.lastSeat && this.skipRows(sectionId)
  }

  change = () => {
    if (this.props.onChange) {
      const { selectedArea, selectedSection, selectedRow, selectedSeat, seatType, sectionOnly} = this.state
      this.props.onChange({selectedArea, selectedSection, selectedRow, selectedSeat, seatType, sectionOnly})
    }
  }

  submit = () => {
    const { selectedArea, selectedSection, selectedRow, selectedSeat } = this.state
    this.props.onSubmit({selectedArea, selectedSection, selectedRow, selectedSeat})
    this.clear(this.depth())
  }

  clear = (level) => {
    switch (level) {
      case 0: this.setState({selectedArea: ""})
      case 1: this.setState({selectedSection: ""})
      case 2: this.setState({selectedRow: ""})
      case 3: this.setState({selectedSeat: ""})
    }
  }

  render() {
    const { selectedArea, selectedSection, selectedRow, selectedSeat } = this.state
    return(
      <div className={`columns is-multiline is-mobile SeatSelector ${this.props.className || ""}`}>
        <div className="column is-half-mobile">
          <Select
            label="Area"
            name="seat_area"
            prompt="Select Area"
            options={zipObject(this.areas(), this.areas())}
            value={this.state.selectedArea}
            onChange={this.updateSelection.bind(null, "selectedArea")}
            className="mb-0"
          />
        </div>
        { this.depth() > 0 &&
          <div className="column is-half-mobile">
            { selectedArea &&
              <Select
                label="Section"
                name="seat_section"
                prompt="Select Section"
                options={this.sectionsFor(selectedArea)}
                value={this.state.selectedSection}
                onChange={this.updateSelection.bind(null, "selectedSection")}
              />
            }
          </div>
        }
        { this.depth() > 1 && !this.skipRows(selectedSection) &&
          <div className="column is-half-mobile">
            { selectedSection && !this.skipRows(selectedSection) &&
              <Select
                label="Row"
                name="seat_row"
                prompt="Select Row"
                options={this.rowsFor(selectedSection)}
                value={this.state.selectedRow}
                onChange={this.updateSelection.bind(null, "selectedRow")}
              />
            }
          </div>
        }
        { this.depth() > 2 && !this.skipSeats(selectedSection) &&
          <div className="column is-half-mobile">
            { (selectedRow || this.skipRows(selectedSection)) &&
              <Select
                label={capitalize(this.state.seatType)}
                name="seat_seat"
                prompt={`Select ${capitalize(this.state.seatType)}`}
                options={this.seatsFor(selectedSection)}
                value={this.state.selectedSeat}
                onChange={this.updateSelection.bind(null, "selectedSeat")}
              />
            }
          </div>
        }
        { selectedSection && this.skipRows(selectedSection) && this.depth() > 1 &&
          <div className="column is-half-mobile" />
        }
        { this.props.action &&
          <div className="column">
            { this.canSubmit() &&
              <span className="button is-primary" onClick={this.submit}>
                { this.props.action }
              </span>
            }
          </div>
        }
      </div>
    )
  }
}
