import "./StoreForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import { pick } from "lodash"
import usesFeatures from 'usesFeatures'

import Form from "Form";
import Input from "Input";
import Header from "Header"
import Textarea from "Textarea"

class StoreForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const storeId = this.props.computedMatch.params.storeId
    if (storeId) {
      this.props.fetchData(`venue_admin/stores/${storeId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("venue_admin/stores/new", this.props.seedFormData)
    }
  }

  headerText = () => this.props.formData.id ? `Edit ${this.props.formData.name}` : "New Store"

  handleSubmit = (e) => {
    e.preventDefault()

    let store = pick(this.props.formData, [ "name", "closestSection", "description", "pickupInstructions" ])
    const storeId = this.props.formData.id
    const method = storeId ? "patch" : "post"
    const url = storeId ? `stores/${storeId}` : "stores"

    let req = request[method](`venue_admin/${url}`, { store: store })
    req.then(({data}) => {
      if (data.success) {
        this.setState({saved: true, storeId: data.store.id})
      } else {
        this.setState({errors: data.errors})
      }
    })
  }


  render() {
    if (!this.props.dataFetched) return false

    const { updateField, formData } = this.props

    if (this.state.saved) return <Redirect to={`/stores/${this.state.storeId}`} />

    return(
      <div className="StoreForm">
        <Header
          text="Stores"
          backLink={`/stores${formData.id ? ("/" + formData.id) : ""}` }
        />
        <Form className="block" title={this.headerText()} onSubmit={this.handleSubmit}>
          <Input
            label="Store Name"
            onChange={updateField.bind(null, "name")}
            value={formData.name}
          />
          <Textarea
            label="Store Description"
            onChange={updateField.bind(null, "description")}
            value={formData.description}
          />
          <Input
            label="Closest Section"
            onChange={updateField.bind(null, "closestSection")}
            value={formData.closestSection}
          />
          <Textarea
            label="Pickup Instructions"
            onChange={updateField.bind(null, "pickupInstructions")}
            value={formData.pickupInstructions}
            placeholder="Let fans know how to pickup their orders from this store"
          />

          <div className="block field is-grouped">
            <p className="control">
              <button onClick={this.handleSubmit} className="button is-primary">{ formData.id ? "Update" : "Create"} Store</button>
            </p>
            <p className="control">
              <Link to={ formData.id ? `/stores/${formData.id}` : "/stores"} className="button">Cancel</Link>
            </p>
          </div>
        </Form>
      </div>
    )
  }
}
export default usesFeatures(['form', 'data'], StoreForm)
