import React, { useState, useEffect } from "react";
import request from "request"

import formatCurrency from "utils/formatCurrency"
import { formatDateTime } from "date_helpers"

import Modal from "Modal"
import Table from "Table"
import DisplayField from "DisplayField"
import AsyncContainer from "AsyncContainer"

const OrderDetails = ({
  orderId,
  onClose,
}) => {
  const [ order, setOrder ] = useState({})
  const [ isLoaded, setIsLoaded ] = useState(false)

  const fetchOrder = () => {
    const req = request.get(`/orders/${orderId}`)
    req.then(({data}) => {
      setOrder(data.order)
      setIsLoaded(true)
    })
  }
  useEffect(fetchOrder, [])

  return(
    <Modal
      isOpen="true"
      size="large"
      toggleModal={onClose}
    >
      <AsyncContainer
        isLoaded={order.id && isLoaded}
      >
        <h2 className="title">Order #{order.number} at {order.store?.name}</h2>
        <div className="columns">
          <div className="column">
            <h3 className="subtitle">Order Timeline</h3>
            <DisplayField label="Order Placed At" value={formatDateTime(order.placedAt)} />
            <DisplayField label="Order Completed At" value={formatDateTime(order.completedAt)} />
            <h3 className="subtitle">Items in Order</h3>
            <Table
              rows={order.products}
              headers={{
                name: "Name",
                modifiers: "Modifiers",
                quantity: "Ordered",
                refunded: "Refunded",
              }}
              formatters={{
                modifiers: (p) => p.modifiers.map((m) => m.name).join(", "),
                refunded: (p) => p.quantity - p.fulfilled
              }}
            />
          </div>
          <div className="column is-one-third">
            <DisplayField label="Order Subtotal" value={formatCurrency(order.subtotal)} />
            <DisplayField label="Order Tip" value={formatCurrency(order.tip)} />
            <DisplayField label="Order Tax" value={formatCurrency(order.taxes)} />
            <DisplayField label="Order Total" value={formatCurrency(order.total)} />

            <DisplayField label="Refunded" value={formatCurrency(order.refunded || 0)} />

          </div>
        </div>
      </AsyncContainer>
    </Modal>
  )
};

export default OrderDetails

