import React from "react";
//import { Link } from "react-router-dom";
//import request from "request"
import usesFeatures from "usesFeatures"
import moment from "moment"
import { flattenDeep } from "lodash"
import { formatCurrency, currencyTableCell } from "lib/utils/formatCurrency"


import Icon from "Icon"
import Table from "Table"
import Header from "Header"

class StoreReport extends React.Component {
  state = {
    expanded: []
  }

  componentDidMount() {
    const { entityId, entityType } = this.props
    if(entityType == "event" || entityType == "report") {
      this.props.fetchData(`venue_admin/reports/${entityId}/store?entityType=${entityType}`)
    } else if (entityType == "season") {
      this.props.fetchData(`venue_admin/reports/seasons/${entityId}/store`)
    }
  }

  storeFormatters = (storeId) => {
    return {
      name: (p) => p.productName ? <span className="product">{ p.productName }</span> : p.name,
      quantity: (p) => p.sold + p.refunded,
      price: (p) => currencyTableCell(p.price),
      total: (p) => currencyTableCell(p.total),
      action: this.iconFor.bind(null, storeId)
    }
  }

  isExpanded = (storeId, catId) => this.state.expanded.indexOf([storeId, catId].join("-")) > -1

  toggleAllRows = (expanded) => {
    const rowIds = []
    const { stores, categories } = this.props

    if (!expanded) {
      stores.map((store) => {
        rowIds.push([store.id, "uncat"].join("-"))

        categories.map((category) => {
          rowIds.push([store.id, category.id].join("-"))
        })
      })
    }
    this.setState({expanded: rowIds})
  }

  toggleRow = (storeId, row) => {
    if(!row.productData) return false

    const rowId = [storeId, row.id].join("-")
    let expanded = this.state.expanded
    let idx = expanded.indexOf(rowId)
    if (idx > -1) {
      expanded.splice(idx, 1)
    } else {
      expanded.push(rowId)
    }
    this.setState({expanded: expanded})
  }

  iconFor = (storeId, row) => {
    if (row.totals) return " "
    if (row.productName) return " "

    const rowId = [storeId, row.id].join("-")
    let expanded = this.isExpanded(storeId, row.id)
    return <Icon icon={`chevron-${expanded ? "up" : "down"}`} />
  }

  render() {
    if (!this.props.dataFetched) return false

    const expandedRows = this.state.expanded.length > 0
    const { data, renderCard, showTax } = this.props

    return(
      <div className="StoreReport">
        <div className="is-flex mt-6">
          <h2 className="title is-size-2 is-flex-grow-1">Sales by Store</h2>
          <span
            onClick={this.toggleAllRows.bind(null, expandedRows)}
            className="button is-link is-outlined no-print"
          >
            { expandedRows ? "Collapse" : "Expand" } All Rows
          </span>
        </div>
        { data.map((storeData) => {
          let rows = storeData.byCategory.map((data) => {
            let r = [ data ]
            if(this.isExpanded(storeData.id, data.id)) {
              r.push(data.productData)
            }
            return r
          })
          return(
            <div className="Store" key={storeData.id}>
              <h3 className="title">
                { storeData.name }
                { storeData.allInclusive &&
                  <span className="ml-2 mb-1 tag is-outlined is-primary is-size-8 hint--top" data-hint="Sales not added to totals">
                    All-Inclusive Store
                  </span>
                }
              </h3>
              <div className="Stats columns">
                { renderCard("", "Store Sales", formatCurrency(storeData.sales))}
                { renderCard("", "Store Tips", formatCurrency(storeData.tips))}
                { showTax &&
                  renderCard("", "Store Tax", formatCurrency(storeData.tax))
                }
                { renderCard("", "Total Orders", storeData.orderCount)}
                { !showTax &&
                  renderCard("", "Items Sold", storeData.itemsCount)
                }
              </div>
              <Table
                showHeader={true}
                headers={STORE_HEADERS}
                tdClasses={STORE_CLASSES}
                formatters={this.storeFormatters(storeData.id)}
                onRowClick={this.toggleRow.bind(null, storeData.id)}
                rows={flattenDeep(rows)}
                className="StoreTable"
              />
            </div>
          )
        })}
      </div>
    )
  }

}

export default usesFeatures(["data"], StoreReport)

const STORE_HEADERS = {
  name: "Name",
  quantity: "Ordered",
  sold: "Fulfilled",
  refunded: "Refunded",
  price: "Price",
  total: "Total",
  action: "",
}

const STORE_CLASSES = {
  name: "name",
  action: "actions"
}

