import "./FileField.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

class FileField extends React.PureComponent {

  handleChange = ({target}) => {
    this.props.onChange(target.files[0])
  }

  render() {
    return(
      <div className="FileField block">
        { this.props.label && <label className="label block">{this.props.label}</label>}
        <div className="file has-name is-info is-fullwidth block">
          <label className="file-label">
            <input onChange={this.handleChange} className="file-input" type="file" />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">
                Choose a file…
              </span>
            </span>
            <span className="file-name">
              { this.props.value ? this.props.value.name : "No File Selected" }
            </span>
          </label>
        </div>
      </div>
    )
  }
}

export default usesFeatures([], FileField)
