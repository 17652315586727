import React from "react";
import "./SignUpView.scss";

import request from "request";
import usesFeatures from "usesFeatures"

import User from "User";
import Input from "Input"
import { Link } from "react-router-dom";

class SignUpView extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    this.props.fetchData(`invitation/${this.props.match.params.invitationToken}`, this.props.seedFormData)
  }

  handleSignUp = (e) => {
    e.preventDefault()
    if (!(this.isFormValid())) return

    const { email, name, password } = this.props.formData
    const params = {
      invitation_token: this.props.match.params.invitationToken,
      email: email,
      name: name,
      password: password,
      password_confirmation: password,
    }

    let req = request.post("invitation", { user: params })
    req.then(({data}) => {
      if(data.success) {
        User.setAuthToken(data.auth_token)
        this.props.history.push("/")
      } else {
        this.setState({formErrros: data.errors})
      }
    })
  }

  matchingPasswords = () => {
    const { user } = this.state
    return user.password == user.passwordConfirmation
  }

  isFormValid = () => {
    const { formData } = this.props

    return((formData.password == formData.passwordConfirmation) && formData.password !== undefined && formData.name !== undefined)
  }

  passwordErrors = () => {
    const { formData } = this.props

    if (!formData.passwordConfirmation) return {}
    if (formData.passwordConfirmation && formData.password == formData.passwordConfirmation) return {}
    return {
      password: "Password and Password Confirmation do not match"
    }
  }

  render() {
    const { serverData } = this.props
    if (serverData && !serverData.valid_token) {
      return(
        <div className="block has-text-centered">
          <p className="block has-text-danger">
            We're sorry, we can't find your invitation token.
            <br />
            Please click the link in your signup email and try again
          </p>
          <p>
            If this issue persists, please contact support
          </p>
          <p>
            Already have an account?  <Link to="/login">Sign in here</Link>
          </p>
        </div>
      )
    }

    const { user } = this.state
    const { formData, updateField } = this.props
    return(
      <div>
        <p className="block"> Welcome to Catch! Complete your signup below</p>
        <form className="block">
          <Input label="Full Name" placeholder="Ron Swanson" onChange={updateField.bind(null, "name")} value={formData.name} />
          <Input label="Password" type="password" onChange={updateField.bind(null, "password")} value={formData.password} />
          <Input
            label="Password Confirmation"
            type="password"
            onChange={updateField.bind(null, "passwordConfirmation")}
            value={formData.passwordConfirmation}
            errorKey="password"
            errors={this.passwordErrors()}
          />

          <div className="block">
            <input disabled={!this.isFormValid()} type="submit" onClick={this.handleSignUp} className="button is-vcentered is-primary is-outlined" value="Sign Up" />
          </div>
        </form>

        <p className="block">
          Already have an account?  <Link to="/login">Sign in here</Link>
        </p>
      </div>
    )
  }
}

export default usesFeatures(["data", "form"], SignUpView)
