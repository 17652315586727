import React, { useState } from "react"
import getInitialData from "hooks/getInitialData"

import AsyncContainer from "AsyncContainer"
import Header from 'Header'
import Table from "Table"

const TerminalsList = () => {
  const { isLoaded, terminals } = getInitialData("/terminals", "terminals")

  if(initialState?.venue?.terminal == false) {
    return(
      <div>
        <Header text="Catch does Point of Sale!" />
        <div className="block" style={{textAlign: "center"}}>
          <p className="is-size-3">Looks like you're not using us for Point of Sale, so there's nothing here.</p>
          <p className="is-size-5">
            Want to hear more about Point of Sale at Catch?
            <br/>
            Reach out to us at <a href="mailto:sales@gocatch.io">sales@goatch.io</a> or your sales representative
          </p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <Header text="Terminals" />
      <div className="block">
        <AsyncContainer isLoaded={isLoaded}>
          <Table
            rows={terminals}
            headers={TERMINAL_HEADERS}
            formatters={TERMINAL_FORMATTERS}
          />
        </AsyncContainer>
      </div>
    </div>
  )
}

const TERMINAL_HEADERS = {
  terminalName: "Terminal Name",
  authCode: "Auth Code",
  printer: "Printer?",
}

const TERMINAL_FORMATTERS = {
  terminalName: (t) => [ t.store?.name, t.number ].join(" Terminal "),
  printer: (t) => t.hasPrinter ? "Yes" : "No",
}

export default TerminalsList

