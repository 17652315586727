import "./SalesGraph.scss";

import React from "react";
import Chart from "react-apexcharts";
import moment from "moment"

export default class SalesGraph extends React.PureComponent {
  render() {
    return(
      <div className="SalesGraph">
        <div className="stat-widget line-stats-widget is-straight">
          { this.props.title &&
            <div className="widget-head">
              <h4 className="dark-inverted">{ this.props.title }</h4>
            </div>
          }
          <div className="line-stats">
            <div className="line-stat">
              <span>This Month</span>
              <span className="current">
                $ { this.props.data[moment().startOf("month").format("YYYY-MM-DD")] || "0.00"}
              </span>
            </div>
            <div className="line-stat">
              <span>Last Month</span>
              <span className="dark-inverted">
                $ { this.props.data[moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD")] || "0.00"}
              </span>
            </div>
          </div>
          <Chart
            type="line"
            height={this.props.height}
            series={[
              {
                name: "Sales",
                data: Object.values(this.props.data),
              }
            ]}
            options={{
              chart: {
                id: "salesgraph",
                group: "salesgraph",
                height: 250,
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              //colors: [themeColors.accent],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                width: [2, 2, 2],
                curve: "smooth",
              },
              grid: {
                row: {
                  colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                  opacity: 0.5,
                },
              },
              xaxis: {
                categories: Object.keys(this.props.data).map((d) => moment(d).format("MMM"))
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return "$" + val;
                  },
                },
              },
            }}
          />
          <div className="chart-media">
            <div className="meta">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
