import "./CategoryList.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Icon from "Icon"
import Table from "Table"
import Switch from "Switch"
import Header from "Header"

class CategoryList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/venue_admin/categories")
  }

  emptyTable = () => {
    return(
      <h3 className="is-size-6 is-italic">
        No Categories Found. Time to <Link to="/categories/new">create one</Link>?
      </h3>
    )
  }

  toggleCategory = (categoryId) => {
    let req = request.post(`venue_admin/categories/${categoryId}/toggle`)
    req.then(() => {
      this.props.fetchData("/venue_admin/categories")
    })
  }

  moveCategory = (categoryId, direction) => {
    if (this.state.isOrdering) return
    this.setState({isOrdering: true })
    let req = request.post(`/venue_admin/categories/${categoryId}/move`, { direction })
    req.then(({data}) => {
      this.props.fetchData("/venue_admin/categories", this.clearOrder)
    })
  }
  clearOrder = () => this.setState({isOrdering: false})

  tableFormatters = () => {
    return({
      up: (c) => c.isFirst ? null : <Icon icon="chevron-up" onClick={this.moveCategory.bind(null, c.id, -1)} />,
      down: (c) => c.isLast ? null : <Icon icon="chevron-down" onClick={this.moveCategory.bind(null, c.id, 1)} />,
      toggle: (c) => <Switch onChange={this.toggleCategory.bind(null, c.id)} value={c.active} />,
      name: (c) => <Link to={`/categories/${c.id}`}>{c.name}</Link>,
      edit: (c) => <Link to={`/categories/${c.id}/edit`}>Edit</Link>
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    return(
      <div className="CategoryList">
        <Header text="Product Categories" />
        <Table
          newRecordPath="/categories/new"
          newRecordText="New Category"
          empty={this.emptyTable()}
          showHeader={true}
          headers={CATEGORY_HEADERS}
          tdClasses={CATEGORY_TD_CLASSES}
          formatters={this.tableFormatters()}
          rows={this.props.categories}
        />
      </div>
    )
  }
}

const CATEGORY_TD_CLASSES = {
  up: "is-narrow",
  down: "is-narrow",
  toggle: "is-narrow",
  productCount: "is-narrow",
  edit: "is-narrow",
}

const CATEGORY_HEADERS = {
  toggle: "",
  up: "",
  down: "",
  name: "Name",
  productCount: "Product Count",
  edit: "",
}

export default usesFeatures(['data'], CategoryList)
