export const formatCurrency = (input) => {
  let formatted = `${input}`
  if (formatted.indexOf(".") == -1) {
    const decimalPosition = formatted.length - 2
    formatted = formatted.slice(0, decimalPosition) + "." + formatted.slice(decimalPosition)
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formatted)
}

export const currencyTableCell = (data) => data ? formatCurrency(data) : null

export default formatCurrency
