import "./DatePicker.scss";

import bulmaCalendar from 'bulma-calendar/dist/js/bulma-calendar.min.js';

import React from "react";
import moment from "moment"

export default class DatePicker extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    bulmaCalendar.attach(this.inputRef.current, {
      type: "date",
      headerPosition: "top",
      showHeader: false,
      dateFormat: "MMM d yyyy",
      minDate: new Date("2020-01-01"),
      displayYearsCount: 6,
    })

    this.inputRef.current.bulmaCalendar.on("select", this.handleOnChange)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    this.inputRef.current.bulmaCalendar.startDate = nextProps.value
    this.inputRef.current.bulmaCalendar.save()
    return true
  }

  handleOnChange = ({data}) => {
    const date = data.value()
    this.setState({date})
    this.props.onChange(date)
  }

  render() {
    return(
      <div className="DatePicker field">
        <div>
          { this.props.label && <label className="label block">{ this.props.label }</label>}

          <input
            ref={this.inputRef}
            type="date"
            className="input"
            onChange={this.handleOnChange}
            placeholder={this.props.placeholder}
            value={ this.props.value || moment().format("MMM D yyyy")}
          />
        </div>
      </div>
    )
  }
}
