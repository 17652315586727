import "./DeleteButton.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import { toLower } from "lodash"

import Input from "Input"
import Modal from "Modal"

export default class DeleteButton extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      deletePrompt: "",
    }
  }

  updateDelete = (value) => this.setState({deletePrompt: value})

  toggleModal = () => {
    this.setState({open: !this.state.open, deletePrompt: ""})
  }

  handleDelete = (e) => {
    e.preventDefault()
    const { id, model, endpoint } = this.props
    const url = `/venue_admin/${endpoint}/${id}`

    const req = request.delete(url)
    req.then(({data}) => {
      if (data.success) {
        this.setState({destroyed: true})
      }
    })
  }

  render() {
    if (this.state.destroyed) return <Redirect to={`/${this.props.endpoint}`} />
    return(
      <div className="DeleteButton block">
        <Modal
          open={this.state.open}
          toggleModal={this.toggleModal}
          actions={
            <>
              <button onClick={this.handleDelete} disabled={this.state.deletePrompt != "DELETE"} className="button is-danger">
                Delete {this.props.model}
              </button>
              <span className="button" onClick={this.toggleModal}>Cancel</span>
            </>
          }
        >
          <div>
            <p className="block is-size-4">Are you sure you want to delete this {toLower(this.props.model)}?</p>
            <p className="block">To delete this {this.props.model}, type DELETE into the box below</p>
            <Input onChange={this.updateDelete} value={this.state.deletePrompt} placeholder="DELETE" />
          </div>
        </Modal>
        <span onClick={this.toggleModal} className="button is-danger">Delete {this.props.model}</span>
      </div>
    )
  }
}
