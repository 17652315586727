import React from 'react'
import ApplicationProviderWrapper from 'ApplicationContext';
import { I18nextProvider } from "react-i18next";
import i18n from "lib/i18n"

export default class App extends React.PureComponent {
  render() {
    return(
      <ApplicationProviderWrapper>
        <I18nextProvider i18n={i18n}>
          {this.props.children}
        </I18nextProvider>
      </ApplicationProviderWrapper>
    )
  }
}

