import "./ResetPassword.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"
import User from "User"

import Form from "Form"
import Input from "Input"

class ResetPassword extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      messages: {}
    }
  }

  matchingPasswords = () => {
    const { formData } = this.props
    return formData.password && formData.password == formData.passwordConfirmation
  }

  handlePasswordChange = () => {
    const { formData } = this.props
    let user = Object.assign({}, formData)
    user.resetToken = this.props.token

    let req = request.patch("password", { user })
    this.setState({messages: null})
    req.then(({data}) => {
      if (data.success) {
        User.setAuthToken(data.auth_token)
        this.setState({passwordChanged: true, messages: { success: "Password Updated" }})
      } else {
        this.setState({messages: data.errors})
      }
    })
  }

  componentDidMount() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let resetToken = params.get("reset_token")
    this.props.fetchData(`/password?reset-token=${resetToken}`)
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.passwordChanged) return <Redirect to="/" />

    const { updateField, formData } = this.props
    const { messages } = this.state

    if (!this.props.valid) return (
      <div className="ResetPassword">
        <div className="has-text-centered has-text-danger block">
          <p className="block">Password reset not found. Plese check the link in your email again</p>
          <p>If the issue persists, you can try <Link to="/forgot-password">Resetting your password again</Link></p>
        </div>
      </div>
    )

    return(
      <div className="ResetPassword">
        <Form onSubmit={this.handlePasswordChange}>
          <Input
            label="Password"
            type="password"
            onChange={updateField.bind(null, "password")}
            value={formData.password}
          />
          <Input label="Password Confirmation" type="password" onChange={updateField.bind(null, "passwordConfirmation")} value={formData.passwordConfirmation} />

          <div className="has-text-centered has-text-danger block">
            { formData.passwordConfirmation && formData.password !== formData.passwordConfirmation && (
              <p className="block">Password and Password Confirmation do not match</p>
            )}
            { messages && messages.password && (
              <p className="block">{messages.password[0]}</p>
            )}
            { messages && messages.password_confirmation && (
              <p className="block">{messages.password_confirmation[0]}</p>
            )}
          </div>

          <input type="submit" disabled={!this.matchingPasswords()} className="block button is-primary" value="Update Password" />
        </Form>
      </div>
    )
  }
}

export default usesFeatures(['data', 'form'], ResetPassword)
