import "./Paginate.scss";

import React from "react";

export default class Paginate extends React.PureComponent {
  state = {}

  changePage = (direction) => {
    this.props.changePage(this.props.page + direction)
  }

  toPage = (page) => {
    this.props.changePage(page)
  }

  renderPageMarker = (page) => {
    return(
      <li onClick={this.toPage.bind(null, page)} >
        <a>{ page }</a>
      </li>
    )
  }

  render() {
    if (!this.props.count) return null

    return(
      <div className="Paginate">
        <div id="paging-first-datatable" className="pagination datatable-pagination pagination-datatables text-center">
          <div className="datatable-info">
            <span>Showing { this.props.from } to { this.props.to } of { this.props.count } items.</span>
          </div>
          { this.props.pages > 1 &&
            <ul className="pagination">
              <li className="active">
                <a data-page="prev" onClick={this.changePage.bind(null, -1)}>
                  <i className="fas fa-angle-left">
                  </i>
                </a>
              </li>
              { this.props.page > 2 && this.renderPageMarker(1) }
              { this.props.prev && this.renderPageMarker(this.props.prev) }
              <li className="active">
                <a>{ this.props.page }</a>
              </li>
              { this.props.next && this.renderPageMarker(this.props.next) }
              { this.props.last > this.props.next && this.props.last > this.props.page &&
                <li onClick={this.toPage.bind(null, this.props.last)} >
                  <a>{ this.props.last } </a>
                </li>
              }
              <li>
                <a data-page="next" onClick={this.changePage.bind(null, 1)}>
                  <i className="fas fa-angle-right">
                  </i>
                </a>
              </li>
            </ul>
          }
        </div>
      </div>
    )
  }
}
