import "./CurrencyInput.scss";

import React from "react";

import { CurrencyInput as VendorCurrencyInput, Locales, Currencies } from "input-currency-react"

export default class CurrencyInput extends React.Component {
  updateValue = (_, _x, value) => this.props.onChange(value)

  render() {
    return(
      <div className="CurrencyInput block field">
        { this.props.label && <label className="label block">{ this.props.label }</label> }
        <VendorCurrencyInput
          className="input has-text-left"
          options={{
            locale: Locales["English (United States)"],
            i18nCurrency: Currencies["US Dollar"],
            allowNegative: this.props.allowNegative || false,
          }}
          value={this.props.value}
          onChangeEvent={this.updateValue}
          placeholder={this.props.placeholder}
        />
      </div>
    )
  }
}
