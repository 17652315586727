import "./CategoryForm.scss";

import React from "react";
import { Link, Redirect } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"

import Form from "Form"
import Input from "Input"
import Textarea from "Textarea"
import Checkbox from "Checkbox"

class CategoryForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const categoryId = this.props.computedMatch.params.categoryId
    if (categoryId) {
      this.props.fetchData(`venue_admin/categories/${categoryId}/edit`, this.props.seedFormData)
    } else {
      this.props.fetchData("venue_admin/categories/new", this.props.seedFormData)
    }
  }

  headerText = () => this.props.id ? `Edit ${this.props.name}` : "New Category"

  handleSubmit = (e) => {
    e.preventDefault();
    const categoryId = this.props.formData.id
    const method = categoryId ? "patch" : "post"
    const url = categoryId ? `categories/${categoryId}` : "categories"

    const category = this.props.formData
    let req = request[method](`venue_admin/${url}`, { category })
    req.then(({data}) => {
      if (data.success) {
        this.setState({saved: true, id: data.category.id})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false
    if (this.state.saved && this.state.id) return <Redirect to={`/categories/${this.state.id}`} />

    const { formData, updateField } = this.props
    return(
      <div className="CategoryForm">
        <Header text="Categories" backLink="/categories" />
        <Form
          title={this.headerText()}
          onSubmit={this.handleSubmit}
        >
          <Input label="Category Name" onChange={updateField.bind(null, "name")} value={formData.name} />
          <Checkbox
            label="Requires Age Verification?"
            onChange={this.props.updateField.bind(null, "verify")}
            value={formData.verify}
          />
          <Textarea label="Category Description" onChange={updateField.bind(null, "description")} value={formData.description} />
          <div className="block field is-grouped">
            <p className="control">
              <button onClick={this.handleSubmit} className="button is-primary">{ formData.id ? "Update" : "Create"} Category</button>
            </p>
            <p className="control">
              <Link to={ formData.id ? `/categories/${formData.id}` : "/categories"} className="button">Cancel</Link>
            </p>
          </div>
        </Form>
      </div>
    )
  }
}

export default usesFeatures(['form', 'data'], CategoryForm)
