import "./CategoryView.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

import Header from "Header"
import Table from "Table"
import DisplayField from "DisplayField"
import DeleteButton from "DeleteButton"
import ImageField from "ImageField"

class CategoryView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const id = this.props.computedMatch.params.categoryId
    this.props.fetchData(`venue_admin/categories/${id}`)
  }

  emptyTable = () => {
    return(
      <h3 className="is-size-6 is-italic">
        No products found.
      </h3>
    )
  }

  render() {
    if (!this.props.dataFetched) return false

    const { category } = this.props
    return(
      <div className="CategoryView">
        <Header
          text="Categories"
          backLink="/categories"
          actions={true}
        >
          <Link className="button is-primary" to={`/categories/${category.id}/edit`}>Edit</Link>
          <DeleteButton model="Category" id={category.id} endpoint="categories" />
        </Header>

        <div className="s-card mb-4">
          <div className="columns">
            <div className="column is-2">
              <ImageField imageUrl={category.imageUrl} type="category" id={category.id} />
            </div>
            <div className="column is-10">
              <h3 className="title">{category.name}</h3>
              <DisplayField label="Description" value={category.description} />
              <DisplayField label="Requires Age Verification?" value={category.verify ? "Yes" : "No"} />
            </div>
          </div>
        </div>

        <div className="s-card">
          <div className="block">
            <h3 className="title">Products in Category</h3>

            <Table
              headers={PRODUCT_HEADERS}
              formatters={PRODUCT_FORMATTERS}
              showHeader={true}
              empty={this.emptyTable()}
              rows={this.props.products}
            />
          </div>
        </div>
      </div>
    )
  }
}

const PRODUCT_HEADERS = {
  name: "Name",
  price: "Price",
}

const PRODUCT_FORMATTERS = {
  name: (p) => <Link to={`/products/${p.id}`}>{p.name}</Link>
}

export default usesFeatures(['data'], CategoryView)
