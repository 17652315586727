import React from "react";

import request from "request"

import Table from "Table"

export default class Menu extends React.PureComponent {
  productsFor = (category) => {
    return this.props.products.filter((p) => p.categoryId == category.simpleId)
  }

  renderCategory = (category, i) => {
    return(
      <div key={i} className="Category block">
        <h3 className="is-flex is-size-4 mb-2">
          { category.name }
        </h3>
        <Table
          className="is-striped"
          headers={PRODUCT_HEADERS}
          rows={this.productsFor(category)}
          showHeaders={true}
          formatters={this.tableFormatters()}
          tdClasses={PRODUCT_CLASSES}
        />
      </div>
    )
  }

  removeProduct = (id) => {
    const url = `venue_admin/stores/${this.props.storeId}/products/${id}`
    let req = request.delete(url)
    req.then(({data}) => {
      if (data.success) {
        this.props.refreshMenu()
      }
    })
  }

  tableFormatters = () => {
    const { removeProduct } = this
    return({
      price: (p) => `$${p.price}`,
      action: (p) => <span className="button is-small is-danger" onClick={removeProduct.bind(null, p.id)}>X</span>,
    })
  }

  render() {
    const { categories } = this.props
    return(
      <div className="Menu block">
        { Object.keys(categories || {}).length == 0 && (
          <p className="has-text-centered is-italic">No products added to the menu, try using Quick Add</p>
        )}
        { categories.map(this.renderCategory) }
      </div>
    )
  }
}

const PRODUCT_CLASSES = {
  price: "is-narrow has-text-right",
  action: "is-narrow"
}

const PRODUCT_HEADERS = {
  name: "Name",
  price: "Price",
  action: "",
}
