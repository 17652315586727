import "./ProductList.scss";

import React from "react";
import { Link } from "react-router-dom";
import usesFeatures from "usesFeatures"
import request from "request"

import Icon from "Icon"
import Table from "Table"
import Header from "Header"

class ProductList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchData("/venue_admin/products")
  }

  productsFor = (category) => {
    return this.props.products.filter((p) => p.categoryId == category.simpleId)
  }

  emptyTable = (category) => {
    return(
      <h3 className="is-size-6 is-italic">
        No Products Found. Time to <Link to={`/products/new?categoryId=${category.simpleId}`}>create one</Link>?
      </h3>
    )
  }

  clearOrder = () => this.setState({isOrdering: false})

  productHeaders = () => {
    const headers = {
      up: "",
      down: "",
      name: "Product Name",
      price: "Price",
      edit: "",
    }

    if(this.props.externalMenus) {
      delete headers.up
      delete headers.down
      delete headers.edit
    }

    return headers
  }

  productFormatters = () => {
    return(
      {
        up: (p) => p.isFirst ? null : <Icon icon="chevron-up" onClick={this.moveProduct.bind(null, p.id, -1)} />,
        down: (p) => p.isLast ? null : <Icon icon="chevron-down" onClick={this.moveProduct.bind(null, p.id, 1)} />,
        name: (p) => <Link to={`/products/${p.id}`}>{p.name}</Link>,
        price: (p) => `$${p.price}`,
        edit: (p) => <Link to={`/products/${p.id}/edit`}>Edit</Link>,
      }
    )
  }

  moveProduct = (productId, direction) => {
    if (this.state.isOrdering) return
    this.setState({isOrdering: true })
    let req = request.post(`/venue_admin/products/${productId}/move`, { direction })
    req.then(({data}) => {
      this.props.fetchData("/venue_admin/products", this.clearOrder)
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { categories } = this.props
    return(
      <div className="ProductList">
        <Header
          text="Products"
          actions={true}
        >
          <Link to="/products/new" className="button is-primary">New Product</Link>
        </Header>
        { categories.map((c) => {
          return(
            <div key={c.id} className="block">
              <h3 className="title">{ c.name }</h3>
              <Table
                empty={this.emptyTable(c)}
                showHeader={true}
                headers={this.productHeaders()}
                formatters={this.productFormatters()}
                rows={this.productsFor(c)}
                tdClasses={TD_CLASSES}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

const TD_CLASSES = {
  up: "is-narrow",
  down: "is-narrow",
  price: "is-narrow pr-4",
  edit: "is-narrow"
}

export default usesFeatures(["data"], ProductList)
