import "./Profile.scss";

import React from "react";
import { Link } from "react-router-dom";
import moment from "moment"
import usesFeatures from 'usesFeatures'

import request from "request"

import Input from "Input"
import Header from "Header"
import DisplayField from "DisplayField"

class Profile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      password: {}
    }
  }

  componentDidMount() {
    this.props.fetchData("/venue_admin/user")
  }

  handlePasswordChange = (e) => {
    e.preventDefault()
    const { formData } = this.props
    let params = {
      current_password: formData.current,
      new_password: formData.new
    }
    let req = request.patch("venue_admin/user", { user: { ...params }})
    this.setState({messages: null})
    req.then(({data}) => {
      if (data.success) {
        this.setState({passwordChanged: true, messages: { success: "Password Updated" }})
      } else {
        this.setState({messages: data.messages})
      }
    })
  }

  render() {
    if (!this.props.dataFetched) return false

    const { user, updateField, formData } = this.props
    const { password, messages, passwordChanged } = this.state
    return(
      <div className="Profile">
        <Header text="My Profile" />
        <div className="columns mt-2">
          <div className="column s-card is-6 is-offset-3">
            <DisplayField label="Full Name" value={user.name} />
            <DisplayField label="Login Email" value={user.email} />
            <DisplayField label="Account Name" value={
              user.accountName ? user.accountName : "No associated account"
            } />
            <DisplayField label="Last logged in" value={moment(user.lastLogin).fromNow()} />

            <div className="block">
              { false && <Link to="/profile" className="button is-primary">Edit Profile (Not functional)</Link>}
            </div>

            <div className="block">
              <h3 className="is-size-4">Change My Password</h3>
              { messages && messages.success && <p className="has-text-success">{messages.success}</p> }
              { !passwordChanged && (
                <form onSubmit={this.handlePasswordChange}>
                  <Input label="Current Password" type="password" onChange={updateField.bind(null, "current")} value={formData.current} />
                  <Input label="New Password" type="password" onChange={updateField.bind(null, "new")} value={formData.new} />

                  { messages && messages.password && (
                    <p className=" block has-text-danger">{messages.password[0]}</p>
                  )}

                  <input type="submit" className="block button is-primary" value="Update Password" />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default usesFeatures(['data', 'form'], Profile)
