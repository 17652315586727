import "./Textarea.scss";

import React from "react";
import { Link } from "react-router-dom";
import request from "request"
import usesFeatures from "usesFeatures"

export default class Textarea extends React.Component {
  handleOnChange = ({target}) => this.props.onChange(target.value)

  render() {
    return(
      <div className="Textarea block">
        { this.props.label && <label className="label block">{ this.props.label }</label> }
        <div className="control">
          <textarea
            className="textarea"
            onChange={this.handleOnChange}
            placeholder={this.props.placeholder}
            value={ this.props.value || ""}
          />
        </div>
      </div>
    )
  }
}
